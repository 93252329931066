import Axios from '@/axios';
import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface SmtpSettingState {
    smtpSetting: SmtpSetting | null;
    smtpSettings: SmtpSettings | null;
    smtpSettingOptions: SmtpSettings | null;
}

export const smtpSetting = merge(getBaseStore('smtpSetting'), {
    actions: {
        async getSmtpSettings({ commit }) {
            const smtpData = await Axios.request({
                method: 'get',
                url: '/api/private/smtp_settings',
                params: {
                    pagination: false,
                    'extra[permissions]': 0,
                    'extra[front_actions]': 0,
                },
            });

            const brevoData = await Axios.request({
                method: 'get',
                url: '/api/private/brevo_api_settings',
                params: {
                    pagination: false,
                    'extra[permissions]': 0,
                    'extra[front_actions]': 0,
                },
            });

            smtpData['hydra:member'] = smtpData['hydra:member'].map((smtpSetting: SmtpSetting) => {
                smtpSetting.provider = 'smtp';
                return smtpSetting;
            });

            brevoData['hydra:member'] = brevoData['hydra:member'].map((brevoSetting: BrevoApiSetting) => {
                brevoSetting.provider = 'brevoApi';
                return brevoSetting;
            });

            commit('updateSmtpSettingOptions', {
                'hydra:member': [...smtpData['hydra:member'], ...brevoData['hydra:member']],
                'hydra:totalItems': smtpData['hydra:member'].length + brevoData['hydra:member'].length,
            });
        },
        async getSmtpSetting({ commit }, { id, provider }: { id: number; provider: MailingProvider }) {
            const data = await Axios.request<any, SmtpSetting | BrevoApiSetting>({
                method: 'get',
                url: `/api/private/${provider === 'smtp' ? 'smtp' : 'brevo_api'}_settings/${id}`,
            });

            data.provider = provider;
            commit('updateSmtpSetting', data);

            return data;
        },
        async createSmtpSetting({ commit, state }: ActionContext<SmtpSettingState, RootState>) {
            commit('updateSmtpSetting', {
                label: '',
                provider: 'smtp',
            });

            return state.smtpSetting;
        },
    },
});
