import { getEnv } from '@/env/env';
import router from '@/router';
import { Capacitor } from '@capacitor/core';
import { getPlatforms } from '@ionic/vue';
import { BrowserTracing } from '@sentry/tracing';
import { Replay, captureException as _captureException, init, setContext, vueRouterInstrumentation } from '@sentry/vue';
import { App } from 'vue';

export const initSentry = (app: App) => {
    init({
        app,
        enabled: import.meta.env.PROD,
        dsn: 'https://357f8a9174084b4f8085998122b3219c@o1290556.ingest.sentry.io/6527426',
        release: getEnv().releaseName,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: vueRouterInstrumentation(router),
                tracePropagationTargets: ['admin.apenday.com', 'preprod.admin.apenday.com', 'int.admin.apenday.com', /^\//],
            }),
            new Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        ignoreErrors: [/Request failed with status code 401/],
        tracesSampleRate: 0.3,
        environment: getEnv().envName,
        beforeBreadcrumb(breadcrumb, hint) {
            if (breadcrumb.category === 'ui.click') {
                const { target } = hint.event;
                const el = target.closest('[data-sentry]');
                if (el && el.dataset?.sentry) {
                    breadcrumb.message = target.dataset.sentry;
                }
            }
            return breadcrumb;
        },
    });

    setContext('additional context', {
        platform: getPlatforms(),
        isApp: Capacitor.isNativePlatform(),
    });
};

export function captureException(err, captureContext = (scope) => scope) {
    if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(err);
    }

    _captureException(err, captureContext);
}
