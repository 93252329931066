// organize-imports-ignore
import { user, UserState } from '@/store/modules/user'; // Need be imported first !
import { agency, AgencyState } from '@/store/modules/agency';
import { appEvent, AppEventState } from '@/store/modules/appEvent';
import { area, AreaState } from '@/store/modules/area';
import { areaCrm, AreaCrmState } from '@/store/modules/areaCrm';
import { areaCrmField, CrmFieldsState } from '@/store/modules/areaCrmField';
import { areaCrmMeeting, AreaCrmMeetingState } from '@/store/modules/areaCrmMeeting';
import { availability, AvailabilityState } from '@/store/modules/availability';
import { constraint, ConstraintState } from '@/store/modules/constraint';
import { closingPeriod, ClosingPeriodState } from '@/store/modules/closingPeriod';
import { dataSource, DataSourceState } from '@/store/modules/dataSource';
import { externalCrmField, ExternalCrmFieldsState } from '@/store/modules/externalCrmField';
import { ftpExport, FtpExportState } from '@/store/modules/ftpExport';
import { globalConfig, GlobalConfigState } from '@/store/modules/globalConfig';
import { group, GroupState } from '@/store/modules/group';
import { hub, HubState } from '@/store/modules/hub';
import { interaction, InteractionState } from '@/store/modules/interaction';
import { invite, InviteState } from '@/store/modules/invite';
import { meeting, MeetingState } from '@/store/modules/meeting';
import { navigation, NavigationState } from '@/store/modules/navigation';
import { offer, OfferState } from '@/store/modules/offer';
import { page, PageState } from '@/store/modules/page';
import { reporting, ReportingState } from '@/store/modules/reporting';
import { scheduledMeeting, ScheduledMeetingState } from '@/store/modules/scheduledMeeting';
import { scheduledMeetingTag, ScheduledMeetingTagState } from '@/store/modules/scheduledMeetingTag';
import { session, SessionState } from '@/store/modules/session';
import { smsSetting, SmsSettingState } from '@/store/modules/smsSetting';
import { smtpSetting, SmtpSettingState } from '@/store/modules/smtpSetting';
import { supportMessage, SupportMessageState } from '@/store/modules/supportMessage';
import { supportThread, SupportThreadState } from '@/store/modules/supportThread';
import { unavailability, UnavailabilityState } from '@/store/modules/unavailability';
import { userCategory, UserCategoryState } from '@/store/modules/userCategory';
import { widget, WidgetState } from '@/store/modules/widget';
import { widgetAvailability, WidgetAvailabilityState } from '@/store/modules/widgetAvailability';
import { userDefaultAvailability, UserDefaultAvailabilityState } from '@/store/modules/userDefaultAvailability';
import { createStore } from 'vuex';
import type { RouteLocationNormalized } from 'vue-router';

export interface RootState {
    navigation: NavigationState;
    user: UserState;
    meeting: MeetingState;
    group: GroupState;
    constraint: ConstraintState;
    availability: AvailabilityState;
    scheduledMeeting: ScheduledMeetingState;
    area: AreaState;
    agency: AgencyState;
    userCategory: UserCategoryState;
    offer: OfferState;
    globalConfig: GlobalConfigState;
    hub: HubState;
    smtpSetting: SmtpSettingState;
    smsSetting: SmsSettingState;
    supportThread: SupportThreadState;
    supportMessage: SupportMessageState;
    reporting: ReportingState;
    page: PageState;
    ftpExport: FtpExportState;
    scheduledMeetingTag: ScheduledMeetingTagState;
    appEvent: AppEventState;
    invite: InviteState;
    unavailability: UnavailabilityState;
    areaCrm: AreaCrmState;
    areaCrmMeeting: AreaCrmMeetingState;
    areaCrmField: CrmFieldsState;
    externalCrmField: ExternalCrmFieldsState;
    interaction: InteractionState;
    widget: WidgetState;
    widgetAvailability: WidgetAvailabilityState;
    userDefaultAvailability: UserDefaultAvailabilityState;
    closingPeriod: ClosingPeriodState;
    session: SessionState;
    dataSource: DataSourceState;
}

export default createStore<RootState>({
    modules: {
        navigation,
        user,
        meeting,
        group,
        constraint,
        availability,
        scheduledMeeting,
        area,
        agency,
        userCategory,
        offer,
        globalConfig,
        hub,
        smtpSetting,
        smsSetting,
        supportThread,
        supportMessage,
        reporting,
        page,
        ftpExport,
        scheduledMeetingTag,
        appEvent,
        invite,
        unavailability,
        areaCrm,
        areaCrmMeeting,
        areaCrmField,
        externalCrmField,
        interaction,
        widget,
        widgetAvailability,
        userDefaultAvailability,
        closingPeriod,
        session,
        dataSource,
    },
    // plugins: [createLogger()]
});

export interface GetItemsPayload {
    params?: any;
    url?: string;
    add?: boolean;
    loading?: (inProgress: boolean) => void;
    prefixName?: string;
    options?: boolean;
    callId?: string;
}

export interface GetItemPayload {
    id?: string;
    params?: object;
    useCommit?: boolean;
    routeLocation?: RouteLocationNormalized;
}
